<template src="./index.html"></template>
<style src="./index.css" scoped></style>
<script>
import {mapState,mapActions} from 'vuex'
export default {
  computed:{
    ...mapState({
      deliverGoodsData: (state) => state.utilities.delivergoods_list,
      selectType:(state) => state.print.expressList,
    }),
  },
  data() {
    return {
      pickerOptions: {
        disabledDate:(date)=>{ // 限制查询3个月内数据
            let moment = this.moment
            if(
                moment(date).isBefore(moment().subtract(2, 'M').startOf("M"))
                || moment(date).isAfter(moment().endOf("D"))
            ){
                return true
            }
        }
      },
      rules:{
        startTime:[
          { required: true, message: '请选择日期', trigger: 'blur' },
        ]
      },
      formData: {
        startTime: [],
        expressList: "所有快递",
        name: "",
        dayin: "",
        orderNum: "",
        bianhao: "",
        BICNum: "",
      },
      params:{},
      currentPage:1,
      offset:1,//页码
      pageNum:50,//每页显示几条
      totalNum: 0,
      tableShow:false,
      selectTypeList:[],
      pageShow:true
    };
  },
  methods: {
    ...mapActions('utilities',['getDeliveryRecord']),
    handleSelect() {
      this.currentPage = 1
      this.offset = 1
      this.pageShow = false
     setTimeout(()=> {
        this.pageShow = true
      },0)
      this.$refs.ruleForm.validate((valid) => {
          if(!valid) return false
          this.params={
              express_code:this.formData.expressList=='所有快递'?'':this.formData.expressList,
              start_time: this.formData.startTime[0],
              end_time: this.formData.startTime[1],
              transaction_id: this.formData.bianhao,
              express_number: this.formData.orderNum,
              nickname: this.formData.name,
          }
          this.getList()
            })
      
    },
    getList() {
      this.tableShow=true;
      let params = {
        limit: this.pageNum,
        pageNum:this.offset,
        start_time: this.getBeginTime(),
        end_time: this.getEndTime(),
      };
      params = { ...params, ...this.params };
      this.getDeliveryRecord(params)
        .then((res) => {
           this.total=res.data.total?res.data.total:'0'
        })
        .catch((error) => {});
    },
    // handleDownLoads(){
    //   console.log('点击了下载按钮');
    // },
     handleCurrentChange(page){
      this.offset=page
      this.getList()
    },
    handleSizeChange(val){
      this.offset=1
      this.pageNum=val
      this.getList()
      this.pageShow = false
      setTimeout(()=> {
        this.pageShow = true
      },0)
    },
     getBeginTime() {
      let now=new Date().getTime();
      let days = 34;
      let agoTimes = now-86400*1000*days;
      let time = new Date(agoTimes);
      let year = time.getFullYear();
      let mounth = time.getMonth() + 1;
      let date = time.getDate();
      mounth = mounth < 10 ? "0" + mounth : mounth;
      date = date < 10 ? "0" + date : date;
      return year + "-" + mounth + "-" + date + " 00:00:00";
    },
    getEndTime() {
      var time = new Date();
      var year = time.getFullYear();
      var mounth = time.getMonth() + 1;
      var date = time.getDate();
      mounth = mounth < 10 ? "0" + mounth : mounth;
      date = date < 10 ? "0" + date : date;
      return year + "-" + mounth + "-" + date + " 23:59:59";
    },
  },
    mounted() {
    this.$store.dispatch('print/get_express_list').then(()=> {
      this.selectTypeList=this.selectType
      this.selectTypeList.unshift({id:'10',name:'全部快递',company_no:''})
      this.$store.commit("print/SET_EXPRESS_LIST", this.selectTypeList);
    })
    },
  created(){
    // this.getList()
    this.formData.startTime.push(this.getBeginTime())
    this.formData.startTime.push(this.getEndTime())
  }
};
</script>
